export const isProduction = process.env.REACT_APP_MODE === 'production';

export const apiUrl = process.env.REACT_APP_API_URL;

export const loginTokenStorageItem = 'TheNextLookLoginToken';

export const colorBrandActive = '#fde000';

export const workspaceUrlPrefix = '/app';

export const pusherKey = isProduction ? 'fd8697748bbe7e70a1b3' : '5c073d291708f68c08d3';

// googleMapKey is the same with the key in `index.html`
export const googleMapKey = 'AIzaSyC8RD_2UCLTHaOiZt28fO52pa-7w-uOXWQ';

export const SEARCHING_TYPE = {
    business: {
        label: 'I am a talent looking for job opportunities',
        value: 'business'
    },
    talent: {
        label: 'I am a business looking for talent',
        value: 'talent'
    },
};

export const MILES_OPTIONS = [5, 10, 20, 40, 50];

export const JOB_TYPE_OPTIONS = [
    'Rental',
    'Commission',
    'Salary (full time)',
    'Salary (part-time)',
    'Hourly'
];

export const EXPERIENCE_OPTIONS = [
    { value: 'Entry', label: 'Entry (0-1 year)' },
    { value: 'Seasoned', label: 'Seasoned (2-4 years)' },
    { value: 'Senior', label: 'Senior (+5 years)' }
];

export const USER_TYPE = {
    TALENT: 1,
    EMPLOYER: 2
};

// Calendar
export const CALENDAR_VIEW_TYPES = {
    month: 'month',
    week: 'week',
    day: 'day'
};


// Appointment Screen
const AVAILABILITY_TIMES_RANGE = {
    start: '8:00',
    end: '20:30'
};

export const AVAILABILITY_TIMES = (() => {
    const out = [];
    let str = AVAILABILITY_TIMES_RANGE.start;
    let strSplitted,
        minute,
        hour;

    while (str !== AVAILABILITY_TIMES_RANGE.end) {
        strSplitted = str.split(':');
        minute = strSplitted[1];
        hour = parseInt(strSplitted[0]);

        if (minute === '30') {
            hour++;
            minute = '00';
        }
        else {
            minute = '30';
        }

        str = `${hour}:${minute}`;

        out.push(str);
    }

    return out;
})();


// general config

/**
 * returns true if user is a talent and false if not (user is business)
 * @param  {Number} typeCode
 * @returns {Boolean}
 */
export const IS_USER_TALENT = typeCode => typeCode === 1;
