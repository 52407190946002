import PropTypes from 'prop-types';
import React from 'react';

const ArrowRight = props => (
    <svg
        height={30}
        viewBox='0 0 30 30'
        width={30}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: 'middle',
            color: props.color,
            ...props.style
        }}>
        <g clipPath='url(#a)'>
            <path fill='#1E201D' d='M9.785 4.771a1.06 1.06 0 0 1 1.512 0 1.066 1.066 0 0 1 0 1.497l-7.665 7.665h25.294c.59 0 1.074.469 1.074 1.059s-.484 1.073-1.074 1.073H3.632l7.665 7.651a1.085 1.085 0 0 1 0 1.512 1.06 1.06 0 0 1-1.512 0l-9.48-9.48a1.067 1.067 0 0 1 0-1.497l9.48-9.48z'/>
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h30v30H0z'/>
            </clipPath>
        </defs>
    </svg>
);

ArrowRight.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default ArrowRight;
