/* eslint-disable no-useless-escape */

import Storage from './Storage';
import { loginTokenStorageItem } from 'config/constants';


/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

/**
 * Validate phone number
 * @param {String} phone Phone number
 * @returns {Boolean}
 */
export function validatePhone(phone) {
    const regex = /^\+(\d){1,4}(\s|\-)+(\d|\s|\-)+\d$/;

    return regex.test(phone);
}

/**
 * Validate Facebook URL
 * @param {String} url URL
 * @returns {Boolean}
 */
export function validateFacebookURL(url) {
    const regex = /^https:\/\/(\w|\.)*facebook.com\/([^\s])+$/;

    return regex.test(url);
}

/**
 * Validate Website URL
 * @param {String} url URL
 * @returns {Boolean}
 */
export function validateWebsiteURL(url) {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    return regex.test(url);
}

/**
 * Validate Instagram URL
 * @param {String} url URL
 * @returns {Boolean}
 */
export function validateInstagramURL(url) {
    const regex = /^https:\/\/(\w|\.)*instagram.com\/([^\s])+$/;

    return regex.test(url);
}


/**
 * Get image from text
 * @param {String} text
 * @returns {Boolean}
 */
export function getImageFromText(text) {
    return text.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:png|gif|jpeg|jpg)/i);
}


/**
 * Save login token
 * @param {String} loginToken
 */
export function saveLoginToken(loginToken) {
    Storage.setItem(loginTokenStorageItem, loginToken);
}


/**
 * Get login token
 * @returns {String}
 */
export function getLoginToken() {
    return Storage.getItem(loginTokenStorageItem);
}


/**
 * Delete login token
 */
export function deleteLoginToken() {
    Storage.removeItem(loginTokenStorageItem);
}


/**
 * Sets the the authorization token for api requests
 */
export function setApiRequestToken() {
    const token = Storage.getItem(loginTokenStorageItem);

    window.Eloquent.setApiRequestToken(token);
}


/**
 * Get the value of the requested param from the current page url
 * @param {String} queryString - the search query
 * @param {String} param - the requested param
 * @returns {String}
 */
export function getUrlSearchParam(queryString, param) {
    const allQueries = queryString.slice(1).split('&');
    let val = '';

    allQueries.forEach((query) => {
        if (query.indexOf(param) !== -1) {
            val = query.split('=')[1];
        }
    });

    return val;
}

/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

/**
 * Get country code of phone number
 * @param {String} phone Valid phone number with country code
 * @returns {String}
 */
export function getCountryCode(phone) {
    const regex = /(\+\d{1,4})(\s|\-)+((\d|\s|\-)+)/;
    const result = regex.exec(phone);

    if (result) {
        return result[1];
    }
}

/**
 * Get phone number part without country code
 * @param {String} phone Valid phone number with country code
 * @returns {String}
 */
export function getPhoneNumber(phone) {
    const regex = /(\+\d{1,4})(\s|\-)+((\d|\s|\-)+)/;
    const result = regex.exec(phone);

    if (result) {
        return result[3];
    }
}

/**
 * Sanitize phone number
 * @param {String} phone
 * @returns {String}
 */
export function sanitizePhone(phone) {
    return phone.replace(/[^0-9]+/g, '');
}

/**
 * Check if phone in array of phone numbers
 * @param {String} phone
 * @param {Array} arrayOfPhones
 * @returns {String}
 */
export function isPhoneInArrayOfPhones(phone, arrayOfPhones) {
    // https://stackoverflow.com/a/7037844/801454
    const joinPhones = (phones) => phones.join('X').replace(/[^\dX]/g, '') + 'X';
    const regex = new RegExp(sanitizePhone(phone) + 'X');

    arrayOfPhones = joinPhones(arrayOfPhones);

    return regex.test(arrayOfPhones);
}
