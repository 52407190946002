import { EventEmitter } from 'fbemitter';

export const AppEvents = {
    onNewMessageNotification: 'NewMessageNotification',
    onNewJobApplicationNotification: 'NewJobApplicationNotification',
    onNewMessage: 'NewMessage',
    onSeenMessage: 'SeenMessage',
};

export default new EventEmitter();
