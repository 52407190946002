const Eloquent = window.Eloquent;

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedUserProfile() {
    return Eloquent.User.me();
}

/**
 * Create a new user
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function getUserById(id, data) {
    return Eloquent.User.getOne(id, data);
}

/**
 * Set logged in user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function setUserProfile(id, data) {
    return Eloquent.User.updateOne(id, data);
}

/**
 * Create user
 * @param {Object} data
 * @returns {Promise}
 */
export function createUser(data) {
    return Eloquent.User.createOne(data);
}

/**
 * Update user
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUser(id, data) {
    return Eloquent.User.updateOne(id, data);
}

/**
 * Get all users
 * @param {Object} params
 * @returns {Promise}
 */
export function getAllUsers(params = {}) {
    return Eloquent.User.getAll(params);
}

/**
 * Get Popular Talents
 * @param {Object} params
 * @returns {Promise}
 */
export function getPopularTalent(params = {}) {
    return Eloquent.User.getFavorites(params);
}
