import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { format } from 'date-fns';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import NotificationBell from 'images/bell.svg';
import './Notifications.css';

export default class Notifications extends PureComponent {
    static propTypes = {
        className: PropTypes.string.isRequired,
        notifications: PropTypes.array.isRequired,
        onNotificationClick: PropTypes.func.isRequired,
    }

    static defaultProps = {
        className: ''
    }

    renderNotification = (notification) => {
        const { onNotificationClick } = this.props;

        return (
            <DropdownItem
                key={notification.id}
                className='Notifications__list-item'
                onClick={onNotificationClick(notification)}>
                <div className='Notifications__list-item-body'>
                    <div className='Notifications__list-item-body-title'>
                        {notification.data.title}
                    </div>

                    <div className='Notifications__list-item-body-time'>
                        {format(notification.created_at, 'MM/DD/YY')}
                    </div>
                </div>

                <div className='Notifications__list-item-footer'>
                    {`${notification.data.description.slice(0, 45)}${notification.data.description.length > 45 ? '...' : ''}`}
                </div>
            </DropdownItem>
        );
    };

    render() {
        const { className, notifications } = this.props;

        return (
            <div className={`Notifications ${className}`}>
                <UncontrolledDropdown
                    nav={true}
                    inNavbar={true}>
                    <DropdownToggle nav={true}>
                        <div className='toggle-container'>
                            <div className='Notifications__icon'>
                                <img className='Notifications__icon-img'
                                    src={NotificationBell}
                                    alt='' />

                                {notifications.length > 0 && (
                                    <span className='Notifications__icon-has-notification' />
                                )}
                            </div>
                        </div>
                    </DropdownToggle>

                    <DropdownMenu
                        className='Notifications__list'
                        right={true}>
                        {notifications.length > 0
                            ? notifications.map(this.renderNotification)
                            : <div className='Notifications__list-empty'>
                                No new notification
                            </div>}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }
}
