import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import 'lazysizes';
import { withRouter, Switch, Route } from 'react-router-dom';
import 'styles/style.css';
import AppLoadingContainer from 'containers/AppLoading/AppLoadingContainer';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import PageLoadable from 'components/Loadable/PageLoadable';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { hideFlashNotification } from 'redux/modules/flashNotification';

const AsyncHome = PageLoadable({ loader: () => import('containers/HomePage/HomeContainer') });
const AsyncNotFoundPage = PageLoadable({ loader: () => import('containers/NotFoundPage/NotFoundPage') });
const AsyncWorkspace = PageLoadable({ loader: () => import('containers/Workspace/WorkspaceContainer') });
const AsyncSignup = PageLoadable({ loader: () => import('containers/SignUp/SignUpContainer') });
const AsyncSearch = PageLoadable({ loader: () => import('containers/SearchPage/SearchPageContainer') });
const AsyncTalentProfile = PageLoadable({ loader: () => import('containers/Profile/Talent/TalentProfileContainer') });
const AsyncBusinessProfile = PageLoadable({ loader: () => import('containers/Profile/Business/BusinessProfileContainer') });
// TOBE_FIXED : Appointment route should be private
const AsyncAppointment = PageLoadable({ loader: () => import('containers/Appointment/AppointmentContainer') });

class AppContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        profileData: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        flashNotificationIsPermanent: PropTypes.bool.isRequired,
        flashNotificationLocation: PropTypes.string.isRequired,
        flashNotificationText: PropTypes.string.isRequired,
        flashNotificationIsWarning: PropTypes.bool.isRequired,
        showFlashNotification: PropTypes.bool.isRequired,
    }

    componentDidMount() {
        // preload page for 404 route
        AsyncNotFoundPage.preload();
    }

    handleHideNotification = () => {
        this.props.dispatch(hideFlashNotification());
    }

    render() {
        const { location, isAuthed } = this.props;

        return (
            <div id='outer-container'>
                <ErrorBoundary location={location}>
                    <HeaderNav location={location} history={this.props.history} />

                    <AppLoadingContainer location={location}>
                        <Switch>
                            <Route path='/' exact={true} component={AsyncHome} />
                            <Route path='/password/reset' component={AsyncHome} />
                            <Route path='/signup/:step?' component={AsyncSignup} />
                            <Route path='/search' component={AsyncSearch} />
                            <Route path='/talent-profile/:id' component={AsyncTalentProfile} />
                            <Route path='/business-profile/:id' component={AsyncBusinessProfile} />
                            <Route path='/appointment' component={AsyncAppointment} />
                            <PrivateRoute path='/app' component={AsyncWorkspace} isAuthed={isAuthed} />
                            <Route component={AsyncNotFoundPage} />
                        </Switch>
                    </AppLoadingContainer>
                </ErrorBoundary>

                <FlashNotification
                    showFlashNotification={this.props.showFlashNotification}
                    text={this.props.flashNotificationText}
                    location={this.props.flashNotificationLocation}
                    permanent={this.props.flashNotificationIsPermanent}
                    isWarning={this.props.flashNotificationIsWarning}
                    onHideNotification={this.handleHideNotification} />
            </div>
        );
    }
}

function mapStateToProps({ authentication, flashNotification, seatLimit }) {
    return {
        isAuthed: authentication.isAuthed,
        profileData: authentication.profileData,
        flashNotificationText: flashNotification.text,
        flashNotificationLocation: flashNotification.location,
        flashNotificationIsPermanent: flashNotification.permanent,
        flashNotificationIsWarning: flashNotification.isWarning,
        showFlashNotification: flashNotification.showFlashNotification,
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(AppContainer)
);
