import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Navbar from 'reactstrap/lib/Navbar';
import Nav from 'reactstrap/lib/Nav';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Collapse from 'reactstrap/lib/Collapse';
import Button from 'reactstrap/lib/Button';
import FaUser from 'react-icons/lib/fa/user';
import { distanceInWordsStrict } from 'date-fns';
import Notifications from 'components/Notifications/Notifications';
import SignIn from 'components/Modals/SignIn';
import { getUrlSearchParam } from 'utils/helpers';
import { checkResetPasswordCode } from 'api/auth';
import { getUserNotifications, setNotificationsAsRead } from 'api/notification';
import { handleLogout } from 'redux/modules/authentication';
import { showFlashNotification } from 'redux/modules/flashNotification';
import { workspaceUrlPrefix } from 'config/constants';
import AppEventEmitter, { AppEvents } from 'utils/appEvents';
import NextLookLogo from 'images/nextlook-logo.svg';
import WhiteMail from 'images/white-mail.svg';
import DarkMail from 'images/dark-mail.svg';
import './HeaderNav.css';

class HeaderNav extends PureComponent {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        profileData: PropTypes.object.isRequired,
    }

    state = {
        isUserDropdownOpen: false,
        isSignInModalOpen: false,
        notifications: [],
    };

    componentDidMount() {
        const { isAuthed } = this.props;

        this.onResetPasswordLink();

        this.newMessageListener = AppEventEmitter.addListener(AppEvents.onNewMessageNotification, this.onNewMessages);
        this.newJobApplicationListener = AppEventEmitter.addListener(AppEvents.onNewJobApplicationNotification, this.onJobApplications);

        if (isAuthed) {
            this.getAllNotifications();
        }
    }

    componentDidUpdate(prevProps) {
        const { isAuthed, location } = this.props;
        const { state } = location;

        if (!prevProps.isAuthed && isAuthed) {
            this.getAllNotifications();
        }

        if (!prevProps.isAuthed && prevProps.isAuthed !== isAuthed) {
            this.onToggleSignInModal(true);
        }

        if ((state && state.isSignInModalOpen) && (prevProps.location.state !== state)) {
            this.onToggleSignInModal(true);
        }
    }

    componentWillUnmount() {
        this.newMessageListener.remove();
        this.newJobApplicationListener.remove();
    }

    onResetPasswordLink = async () => {
        const { location, dispatch } = this.props;
        const { search } = location;

        if (search && search.length > 0) {
            const resetCode = getUrlSearchParam(search, 'code');

            if (resetCode.length === 0 || resetCode === '') {
                return;
            }

            try {
                await checkResetPasswordCode(resetCode);

                this.passwordResetCode = resetCode;
            }
            catch (error) {
                dispatch(showFlashNotification({
                    text: 'Sorry, your password reset link has expired or is invalid',
                    isWarning: true
                }));
            }
            finally {
                this.onToggleSignInModal(true);
            }
        }
    }

    onNewMessages = (event) => {
        const data = {
            id: event.id,
            type: 1,
            title: 'New Message',
            content: event.message.content,
            time: distanceInWordsStrict(new Date(), new Date(event.message.created_at)),
            messageId: event.message.id
        };

        this.setState(prevState => ({
            notifications: [data, ...prevState.notifications],
            isNewNotifications: true
        }));
    }

    onJobApplications = (event) => {
        const data = {
            id: event.id,
            type: 2,
            title: 'New Job Application',
            content: event.jobApplication.title,
            time: distanceInWordsStrict(new Date(), new Date(event.jobApplication.created_at)),
            jobId: event.jobApplication.id
        };

        this.setState(prevState => ({
            notifications: [data, ...prevState.notifications],
            isNewNotifications: true
        }));
    }

    onNotificationClick = (notification) => () => {
        const { dispatch } = this.props;
        const { notifications } = this.state;
        const filteredNotification = notifications
            .slice()
            .filter((_notification) => _notification.id !== notification.id);

        this.setState({
            notifications: [...filteredNotification],
            isPopoverOpen: false
        });

        setNotificationsAsRead(notification.id);

        if (notification.type === 1) {
            dispatch(push(`${workspaceUrlPrefix}/messages/${notification.messageId}`));
        }
        else if (notification.type === 2) {
            dispatch(push(`${workspaceUrlPrefix}/application/${notification.jobId}`));
        }
    }

    onToggleUserDropdown = () => {
        this.setState((state) => ({
            isUserDropdownOpen: !state.isUserDropdownOpen
        }));
    }

    onToggleSignInModal = (isOpen = false) => {
        this.setState({ isSignInModalOpen: isOpen });
    }

    onPressProfile = () => {
        const { profileData, dispatch } = this.props;
        const { id, type, business } = profileData;

        if (type === 1) {
            dispatch(push(`/talent-profile/${id}`));
        }
        else if (type === 2 && business !== null) {
            dispatch(push(`/business-profile/${business.id}`));
        }
    }

    onPressAccount = () => {
        const { dispatch } = this.props;

        dispatch(push(`${workspaceUrlPrefix}/account-settings`));
    }

    onSignOut = () => {
        const { dispatch } = this.props;

        dispatch(handleLogout());
    }

    async getAllNotifications() {
        try {
            const { data } = await getUserNotifications();

            this.setState({ notifications: data });
        }
        catch (error) {
            //
        }
    }

    renderAuthedMenu = (className = '') => {
        const { profileData } = this.props;
        const { notifications } = this.state;

        return (<>
            <Nav
                className={`HeaderNav__authed ${className}`}
                navbar={true}>
                <Notifications
                    className={`HeaderNav__authed-notifications ${className}`}
                    notifications={notifications}
                    onNotificationClick={this.onNotificationClick} />

                <UncontrolledDropdown
                    className={`HeaderNav__authed-profile ${className}`}
                    nav={true}
                    inNavbar={true}>
                    <DropdownToggle
                        className='d-flex align-items-center'
                        nav={true}>
                        <div className='HeaderNav__avatar'>
                            {profileData.avatar
                                ? <img
                                    src={profileData.avatar}
                                    alt='avatar' />
                                : <FaUser
                                    size={30}
                                    color={'#000'} />}
                        </div>
                        <span className='HeaderNav__authed-profile-email'>
                            {profileData.name ? profileData.name : profileData.email}
                        </span>
                    </DropdownToggle>

                    <DropdownMenu
                        className='HeaderNav__authed-profile-dropdown-menu'
                        right={true}>
                        {(profileData.type === 1 || (profileData.type === 2 && profileData.business !== null))
                            ? <DropdownItem
                                className='HeaderNav__dropdown-item'
                                onClick={this.onPressProfile}>
                                PROFILE
                            </DropdownItem>
                            : null}

                        <DropdownItem
                            className='HeaderNav__dropdown-item'
                            onClick={this.onPressAccount}>
                            ACCOUNT
                        </DropdownItem>

                        <DropdownItem
                            className='HeaderNav__dropdown-item -is-red'
                            onClick={this.onSignOut}>
                            SIGN OUT
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </>);
    }

    render() {
        const { location, isAuthed } = this.props;
        const { isUserDropdownOpen, isSignInModalOpen } = this.state;

        if (location.pathname.includes('/signup')) {
            return null;
        }

        return (
            <div>
                <Navbar
                    className='HeaderNav'
                    expand='lg'
                    light={true}>
                    <Link
                        className='HeaderNav__brand navbar-brand'
                        to='/'>
                        <img
                            src={NextLookLogo}
                            alt='logo' />
                    </Link>

                    {isAuthed && this.renderAuthedMenu('--top')}

                    <NavbarToggler
                        className='toggler-button'
                        onClick={this.onToggleUserDropdown} />

                    <Collapse
                        isOpen={isUserDropdownOpen}
                        navbar={true}>

                        <ul className='HeaderNav__list'>
                            <li className='HeaderNav__list-item'>
                                <NavLink
                                    to='/'
                                    exact={true}
                                    activeClassName='-is-active'>
                                    Home
                                </NavLink>
                            </li>

                            <li className='HeaderNav__list-item'>
                                <NavLink
                                    to='/search'
                                    activeClassName='-is-active'>
                                    Search
                                </NavLink>
                            </li>

                            {isAuthed
                                ? <>
                                    <li className='HeaderNav__list-item'>
                                        <NavLink
                                            to={`${workspaceUrlPrefix}/favorites`}
                                            activeClassName='-is-active'>
                                            Favorites
                                        </NavLink>
                                    </li>

                                    <li className='HeaderNav__list-item'>
                                        <NavLink
                                            to={`${workspaceUrlPrefix}/application`}
                                            activeClassName='-is-active'>
                                            Applications
                                        </NavLink>
                                    </li>

                                    <li className='HeaderNav__list-item'>
                                        <NavLink
                                            to={`${workspaceUrlPrefix}/messages`}
                                            activeClassName='-is-active'>
                                            Messages
                                        </NavLink>
                                    </li>

                                    <li className='HeaderNav__list-item'>
                                        <NavLink
                                            to='/appointment'
                                            activeClassName='-is-active'>
                                            Appointment
                                        </NavLink>
                                    </li>
                                </>
                                : null}
                        </ul>

                        {isAuthed
                            ? this.renderAuthedMenu('--bottom')
                            : <div className='HeaderNav__buttons-container'>
                                <Button
                                    className='HeaderNav__buttons -login'
                                    onClick={this.onToggleSignInModal}>
                                    <img
                                        src={WhiteMail}
                                        alt='' />
                                    Login
                                </Button>

                                <Link
                                    className='HeaderNav__buttons -signup'
                                    to='/signup'>
                                    <img
                                        src={DarkMail}
                                        alt='' />
                                    Sign Up
                                </Link>
                            </div>}
                    </Collapse>
                </Navbar>

                {!isAuthed &&
                    <SignIn
                        isOpen={isSignInModalOpen}
                        passwordResetCode={this.passwordResetCode}
                        onClose={this.onToggleSignInModal} />}
            </div>
        );
    }
}

function mapStateToProps({ authentication }) {
    return {
        isAuthed: authentication.isAuthed,
        profileData: authentication.profileData
    };
}

export default connect(
    mapStateToProps
)(HeaderNav);
