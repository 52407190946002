import React, { PureComponent } from 'react';
import { ModalBody, Modal, Form, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import queryString from 'query-string';
import Logo from '../../images/LogoTNL.png';
import MdClose from 'react-icons/lib/md/close';
import Spinner from 'components/Spinner/Spinner';
import ArrowRight from '../../components/Icons/ArrowRight';
import { handleLoginViaEmail } from 'redux/modules/authentication';
import { sendResetPasswordCode, resetPassword } from 'api/auth';
import { showFlashNotification } from 'redux/modules/flashNotification';
import { validateEmail } from 'utils/helpers';
import './modals.css';

class SignInModal extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        isAuthenticating: PropTypes.bool.isRequired,
        passwordResetCode: PropTypes.string,
        location: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    state = {
        isPasswordProcessing: false,
        isForgotPassword: false,
        isStep2: false,
        isInvalidEmail: false,
        isInvalidPassword: false,
        isInvalidConfirmPassword: false,
        isConfirmPasswordFail: false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen && !this.props.isOpen) {
            this.formRef && this.formRef.reset();

            this.setState({
                isForgotPassword: false,
                isStep2: false,
                isInvalidPassword: false,
                isInvalidEmail: false,
                isInvalidConfirmPassword: false,
                isConfirmPasswordFail: false,
            });
        }

        if (!prevProps.passwordResetCode && this.props.passwordResetCode) {
            this.showStep2();
        }
    }

    showForgetPassword = () => {
        this.formRef && this.formRef.reset();

        this.setState((prevState) => ({
            isInvalidEmail: false,
            isInvalidPassword: false,
            isForgotPassword: !prevState.isForgotPassword
        }));
    }

    showStep2 = () => {
        this.formRef && this.formRef.reset();

        this.setState({
            isInvalidPassword: false,
            isInvalidConfirmPassword: false,
            isConfirmPasswordFail: false,
            isPasswordProcessing: false,
            isForgotPassword: false,
            isStep2: !this.state.isStep2
        }, () => {
            !this.state.isStep2 && this.props.dispatch(replace('/'));
        });
    }

    onSignIn = (e) => {
        e.preventDefault();
        const { location } = this.props;
        const email = e.target.email.value;
        const password = e.target.password.value;

        const redirect = queryString.parse(location.search).redirect || '';

        this.setState({
            isInvalidEmail: !validateEmail(email),
            isInvalidPassword: password.length === 0 ? true : false
        });

        if (!validateEmail(email) || password.length === 0) {
            return;
        }

        this.props.dispatch(handleLoginViaEmail(email, password, redirect));
    }

    onEmailSubmit = async (e) => {
        e.preventDefault();

        const email = e.target.email.value;

        this.setState({
            isInvalidEmail: !validateEmail(email) ? true : false
        });

        if (!validateEmail(email)) {
            return;
        }

        this.setState({
            isPasswordProcessing: true
        });

        try {
            await sendResetPasswordCode(email);

            this.props.dispatch(showFlashNotification({
                text: 'A link to reset your password has been successfully sent to your email address'
            }));
        }
        catch (error) {
            let message = 'An error occured while sending you the link to reset your password. Try again.';

            if (error.response.status === 404) {
                message = error.response.data.message;
            }

            this.props.dispatch(showFlashNotification({
                text: message,
                isWarning: true
            }));
        }
        finally {
            this.setState({
                isPasswordProcessing: false
            });
        }
    }

    onNewPasswordSubmit = async (e) => {
        e.preventDefault();

        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        this.setState({
            isInvalidPassword: password.length === 0 ? true : false,
            isInvalidConfirmPassword: confirmPassword.length === 0 ? true : false
        });

        if (password.length === 0 || confirmPassword.length === 0) {
            return;
        }

        this.setState({
            isConfirmPasswordFail: password !== confirmPassword ? true : false
        });

        if (password !== confirmPassword) {
            return;
        }

        this.setState({
            isPasswordProcessing: true
        });

        try {
            await resetPassword(this.props.passwordResetCode, confirmPassword);

            this.showStep2();

            this.props.dispatch(showFlashNotification({
                text: 'Your password has been reset successfully'
            }));
        }
        catch (error) {
            this.props.dispatch(showFlashNotification({
                text: 'An error occured while resetting your account with the new password. Try again.',
                isWarning: true
            }));

            this.setState({
                isPasswordProcessing: false
            });
        }
    }

    onClose = (e) => {
        const { onClose } = this.props;

        onClose();
    }

    render() {
        const { isOpen, isAuthenticating } = this.props;
        const { isForgotPassword, isStep2, isPasswordProcessing, isInvalidEmail, isInvalidPassword, isInvalidConfirmPassword, isConfirmPasswordFail } = this.state;

        return (
            <Modal
                isOpen={isOpen}
                className='sign-in'
                toggle={this.onClose}>
                <ModalBody>
                    <div className='sign-in__close'>
                        <MdClose size={45} color={'#1e201d'} onClick={this.onClose} />
                    </div>
                    <div className={`sign-in__body ${isForgotPassword || isStep2 ? '-small' : ''}`}>
                        <div className='sign-in__logo-container'>
                            {isForgotPassword || isStep2
                                ? <ArrowRight
                                    className='sign-in__back'
                                    onClick={isForgotPassword ? this.showForgetPassword : this.showStep2} />
                                : null}
                            <img src={Logo} alt='logo' />
                        </div>

                        {!isForgotPassword && !isStep2
                            ? <Form innerRef={ref => this.formRef = ref} onSubmit={this.onSignIn}>
                                <div className='d-flex'>
                                    <p className='sign-in__tab mr-5 -is-active'>Sign in</p>
                                </div>

                                <FormGroup className='sign-in__form-group'>
                                    <Label className='sign-in__label'>Email</Label>
                                    <Input
                                        type='email'
                                        name='email'
                                        invalid={isInvalidEmail} />
                                </FormGroup>

                                <FormFeedback className={`sign-in__feedback-message ${isInvalidEmail ? '-show' : ''}`}>
                                    Please fill in a valid email address
                                </FormFeedback>

                                <FormGroup className='sign-in__form-group'>
                                    <Label className='sign-in__label'>Password</Label>
                                    <Input
                                        type='password'
                                        name='password'
                                        invalid={isInvalidPassword} />
                                </FormGroup>

                                <FormFeedback className={`sign-in__feedback-message ${isInvalidPassword ? '-show' : ''}`}>
                                    Please fill in your password
                                </FormFeedback>

                                <p className='sign-in__forget' onClick={this.showForgetPassword}>
                                    Forgot password?
                                </p>

                                <div className='sign-in__btn-container'>
                                    <Button className='btn--primary sign-in__btn' type='submit'>
                                        {isAuthenticating
                                            ? <Spinner size={25} color='#fff' />
                                            : null}
                                        Sign in
                                    </Button>
                                </div>

                                <p className='sign-in__account'>Don't have an account?
                                    <strong>&nbsp;
                                        <NavLink className='sign-in__sign' to='/signup'>
                                            <u>Sign up</u>
                                        </NavLink>
                                    </strong>
                                </p>
                            </Form>
                            : isStep2
                                ? <Form innerRef={ref => this.formRef = ref} onSubmit={this.onNewPasswordSubmit}>
                                    <p className='sign-in__password'>Reset Password</p>

                                    <FormGroup className='sign-in__form-group'>
                                        <Label className='sign-in__label'>New Password</Label>
                                        <Input
                                            type='password'
                                            name='password'
                                            invalid={isInvalidPassword} />
                                    </FormGroup>

                                    <FormFeedback className={`sign-in__feedback-message ${isInvalidPassword ? '-show' : ''}`}>
                                        Please fill in a new password
                                    </FormFeedback>

                                    <FormGroup className='sign-in__form-group'>
                                        <Label className='sign-in__label'>Confirm Password</Label>
                                        <Input
                                            type='password'
                                            name='confirmPassword'
                                            invalid={isInvalidPassword || isConfirmPasswordFail} />
                                    </FormGroup>

                                    <FormFeedback className={`sign-in__feedback-message ${isInvalidConfirmPassword || isConfirmPasswordFail ? '-show' : ''}`}>
                                        {isInvalidConfirmPassword
                                            ? 'Please confirm your new password'
                                            : 'Please the password does not match'}
                                    </FormFeedback>

                                    <div className='sign-in__btn-container'>
                                        <Button
                                            className='mt-4 btn--primary sign-in__btn'
                                            type='submit'>
                                            {isPasswordProcessing
                                                ? <Spinner size={25} color='#fff' />
                                                : null}
                                            Reset password
                                        </Button>
                                    </div>
                                </Form>
                                : <Form innerRef={ref => this.formRef = ref} onSubmit={this.onEmailSubmit}>
                                    <p className='sign-in__password'>Forgot Password</p>

                                    <FormGroup className='sign-in__form-group'>
                                        <Label className='sign-in__label'>Email</Label>
                                        <Input
                                            type='email'
                                            name='email'
                                            invalid={isInvalidEmail} />
                                    </FormGroup>

                                    <FormFeedback className={`sign-in__feedback-message ${isInvalidEmail ? '-show' : ''}`}>
                                        Please fill in a valid email address
                                    </FormFeedback>

                                    <p className='sign-in__send'>
                                        We will send the reset password link to your email ID shortly. :
                                    </p>

                                    <div className='sign-in__btn-container'>
                                        <Button
                                            className='btn--primary sign-in__btn'
                                            type='submit'>
                                            {isPasswordProcessing
                                                ? <Spinner size={25} color='#fff' />
                                                : null}
                                            Send
                                        </Button>
                                    </div>
                                </Form>}
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps({ authentication }) {
    return {
        isAuthenticating: authentication.isAuthenticating
    };
}

export default connect(
    mapStateToProps
)(withRouter(SignInModal));
