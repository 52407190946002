const Eloquent = window.Eloquent;

/**
 * Get all business
 * @param {Object} data
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function getAllBusiness(data = {}) {
    return Eloquent.Business.getAll(data);
}

/**
 * Get a business by an id
 * @param {Number} id
 * @param {Object} data
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function getBusiness(id, data) {
    return Eloquent.Business.getOne(id, data);
}

/**
 * Create a new business
 * @param {Object} data
 * @returns {Promise}
 */
export function createBusiness(data) {
    return Eloquent.Business.createOne(data);
}

/**
 * Update an existing business
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateBusiness(id, data) {
    return Eloquent.Business.updateOne(id, data);
}

/**
 * Get all business favorite
 * @param {Object} params
 * @returns {Promise}
 */
export function getAllBusinessFavorite(params = {}) {
    return Eloquent.BusinessFavorite.getAll(params);
}

/**
 * Remove Business Favorite
 * @param {Number} id
 * @returns {Promise}
 */
export function createBusinessFavorite(id) {
    return Eloquent.BusinessFavorite.createOne(id);
}

/**
 * Remove Business Favorite
 * @param {Number} id
 * @returns {Promise}
 */
export function removeBusinessFavorite(id) {
    return Eloquent.BusinessFavorite.deleteOne(id);
}

/**
 * Get popular Business
 * @param {Object} data
 * @returns {Eloquent}
 */
export function getPopularBusiness(data = {}) {
    return Eloquent.Business.getFavorites(data);
}
