const Eloquent = window.Eloquent;

/**
 * Get all user received notifications
 * @returns {Promise}
 */
export function getUserNotifications() {
    // return new Promise(r => r({ data: [] }));
    return Eloquent.Notification.getAll();
}


/**
 * Set markAsRead flag for an array of user notifications
 * @param {Array} notificationIds
 * @returns {Promise}
 */
export function setNotificationsAsRead(notificationIds) {
    return Eloquent.Notification.markAsRead({ ids: [notificationIds] });
}
